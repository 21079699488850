// External
import React from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Image from "react-bootstrap/Image";
import styled from "styled-components";

const TopBarAlert = styled(Alert)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #04547d;
  color: white;
  border: none;
  border-radius: 0;

  p {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 0.8rem;
  }
`;

export const TopBar = ({ content }) => (
  <TopBarAlert variant="primary">
    <Image
      src="/checkout/icon.png"
      width={35}
      alt="Ícone que representa o IBFT"
    />
    <p>{content}</p>
  </TopBarAlert>
);

TopBar.propTypes = {
  content: PropTypes.string,
};
