import "normalize.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageError from "./Pages/PageError";
import PageHome from "./Pages/PageHome";
import PageLogin from "./Pages/PageLogin";
import PageLogout from "./Pages/PageLogout";
import PageBrand from "./Pages/PageBrand";

import PrivateRoute from "./components/Auth/PrivateRoute";
import PublicRoute from "./components/Auth/PublicRoute";
import PageNotCertified from "./Pages/PageNotCertified";

function App() {
  return (
    <main>
      <Router>
        <Switch>
          <PrivateRoute path="/" exact component={PageHome} />
          <PrivateRoute path="/brand/:id" exact component={PageBrand} />
          <PublicRoute component={PageLogout} path="/logout" exact />
          <PublicRoute
            component={PageNotCertified}
            path="/terapeuta-nao-certificado"
            exact
          />
          <PublicRoute
            restricted={true}
            component={PageLogin}
            path="/login"
            exact
          />
          <Route path="*">
            <PageError />
          </Route>
        </Switch>
      </Router>
    </main>
  );
}

export default App;
