import axios from "axios";

import { setLogin, user } from "./components/utils/auth";

export const defaultRequestHeaders = {
  "Access-Control-Allow-Origin": "*",
  Accept: "application/json",
  "Content-Type": "application/json",
};

const instance = axios.create({
  // baseURL: "https://apolo-staging.herokuapp.com/api/v1/",
  baseURL: "https://apolo.ibft.app/api/v1/",
  timeout: 70000,
  headers: defaultRequestHeaders,
});

export const getAuthHeaders = () => {
  const { token, client, uid } = user();

  return {
    "access-token": token,
    client,
    uid,
  };
};

instance.interceptors.request.use(
  config => {
    const userData = user();

    if (userData) {
      config.headers["access-token"] = userData.token;
      config.headers["client"] = userData.client;
      config.headers["uid"] = userData.uid;
    }

    return config;
  },
  error => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  },
);

export const api = instance;

export const refreshUserAuth = async () => {
  const request = await instance.get("/auth/validate_token");

  const { "access-token": token, client, uid } = request.headers;
  const {
    name,
    apoloAccessEnabled,
    citrgBrandingUsageAcceptedTermsAt,
    brandingUsageAcceptedTermsAt,
    brandingUsageDownloadedAt,
    isCertified,
  } = request.data.data;

  setLogin({
    token,
    client,
    uid,
    name,
    apoloAccessEnabled,
    citrgBrandingUsageAcceptedTermsAt,
    brandingUsageAcceptedTermsAt,
    brandingUsageDownloadedAt,
    isCertified,
  });
};
