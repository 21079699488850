import React, { useEffect } from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { user } from "../components/utils/auth";
import { refreshUserAuth } from "../api";

import { TopBar } from "../components";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import { BiErrorCircle } from "react-icons/bi";

const ErrorIcon = styled(BiErrorCircle)`
  font-size: 5rem;
  color: #f00;
  margin-bottom: 1.5rem;
`;

export const PageNotCertified = () => {
  const userData = user();

  useEffect(() => {
    refreshUserAuth();
  }, []);

  return (
    <div>
      <TopBar content="IBFT — Uso da Marca" />

      <Container className="mt-5">
        <Row className="justify-content-center align-items-center">
          <Col md="5" className="text-center">
            <ErrorIcon />
            <h2 className="w-100 text-center mb-4">Olá, {userData.name}.</h2>

            <Card>
              <Card.Body>
                <Card.Title as="h5">
                  Infelizmente você não possui acesso
                </Card.Title>

                <p>
                  Você precisa ser um terapeuta certificado para ter acesso a
                  este conteúdo.{" "}
                  <a
                    href="https://www.ibft.com.br/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Entre em contato
                  </a>{" "}
                  com a equipe do IBFT para mais informações caso você tenha
                  interesse em se tornar um terapeuta certificado, ou se esta
                  mensagem foi exibida por engano.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="text-center my-3">
        <Link to="/logout">Sair</Link>
      </div>
    </div>
  );
};

export default PageNotCertified;
