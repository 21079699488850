import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

import { getAuthHeaders } from "../../api";

export const DownloadButton = ({ brand }) => {
  const encodedAuthHeaders = window.btoa(JSON.stringify(getAuthHeaders()));

  const downloadBrand = () => {
    const downloadURL = "/.netlify/functions/download-brand";
    return `${downloadURL}?authHeaders=${encodedAuthHeaders}&brand=${brand.type}`;
  };

  return (
    <div className="d-flex flex-column justify-content-center text-center">
      <Button
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        variant="primary"
        size="lg"
        className="mt-2"
        href={downloadBrand()}
      >
        Fazer download
      </Button>

      <p className="mt-2">
        O arquivo está no formato <strong>.zip</strong>.{" "}
      </p>

      <p className="mt-2">
        O download está condicionado ao{" "}
        <a href={brand.termsUrl} rel="noreferrer" target="_blank">
          termos do contrato de licenciamento temporário de uso de marca
        </a>{" "}
        aceito por você anteriormente.
      </p>
    </div>
  );
};

DownloadButton.propTypes = {
  brand: PropTypes.object,
};
