import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { TopBar } from "../components";
import { LoginForm } from "../components";

export const PageLogin = () => {
  return (
    <div style={{ height: "100vh" }} className="d-md-flex flex-column">
      <TopBar content="IBFT — Uso da Marca" />

      <Container className="my-auto">
        <Row className="justify-content-center align-items-center align-center">
          <Col lg={8}>
            <Card>
              <Card.Body>
                <Card.Title as="h5">Faça seu login</Card.Title>
                <p>Utilize o mesmo login e senha da plataforma de IBFT.</p>

                <LoginForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PageLogin;
