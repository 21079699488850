import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

export const UserFormFields = ({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  errors,
  brand,
}) => {
  const alertErrors = errors.global_error || Object.values(errors)?.join(", ");

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Check
        type="switch"
        id="acceptedTerms"
        name="acceptedTerms"
        label={
          <span>
            Eu assumo que li e aceito todos os{" "}
            <a href={brand?.termsUrl} rel="noreferrer" target="_blank">
              termos do contrato de licenciamento temporário de uso de marca
            </a>
            .
          </span>
        }
        error={errors.acceptedTerms}
        onBlur={handleBlur}
        onChange={handleChange}
        checked={values.acceptedTerms}
      />

      <br />

      {alertErrors && <Alert variant="danger">{alertErrors}</Alert>}

      <div className="d-grid gap-2">
        <Button
          disabled={isSubmitting}
          variant="success"
          type="submit"
          className="mt-3"
        >
          {isSubmitting && (
            <div>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Processando...</span>
              </Spinner>
            </div>
          )}

          {!isSubmitting && <div>Salvar minha decisão</div>}
        </Button>
      </div>
    </Form>
  );
};

UserFormFields.propTypes = {};
