const KEY = "apolo_internship_auth";

export const isLogged = () => !!user();

export const setLogin = data => localStorage.setItem(KEY, JSON.stringify(data));

export const logout = () => localStorage.removeItem(KEY);

export const user = () => {
  const userData = localStorage.getItem(KEY);

  if (!userData) {
    return null;
  }

  return JSON.parse(userData);
};
