import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { user } from "../components/utils/auth";

import { TopBar } from "../components";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { refreshUserAuth } from "../api";
import { hasDate } from "../components/utils/date_utils";

export const PageHome = () => {
  const userData = user();

  useEffect(() => {
    refreshUserAuth();
  }, []);

  return (
    <div>
      <TopBar content="IBFT — Uso da Marca" />

      <Container className="mt-5">
        <Row className="justify-content-center align-items-center">
          <Col md="5">
            <h2 className="w-100 text-center mb-4">Olá, {userData.name}.</h2>
            <Card>
              <Card.Body>
                <Card.Title as="h5">Selecionar Marca</Card.Title>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Marca</th>
                      <th scope="col" className="text-center">
                        Termo Aceito?
                      </th>
                      <th scope="col" className="text-right">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>IBFT</td>
                      <td className="text-center">
                        {hasDate(userData?.brandingUsageAcceptedTermsAt)}
                      </td>
                      <td className="text-right">
                        <Link to="/brand/ibft">Acessar</Link>
                      </td>
                    </tr>

                    {userData?.apoloAccessEnabled && (
                      <tr>
                        <td>CITRG</td>
                        <td className="text-center">
                          {hasDate(userData?.citrgBrandingUsageAcceptedTermsAt)}
                        </td>
                        <td className="text-right">
                          <Link to="/brand/citrg">Acessar</Link>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="text-center my-3">
        <Link to="/logout">Sair</Link>
      </div>
    </div>
  );
};

export default PageHome;
