import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { user } from "../components/utils/auth";

import { TopBar } from "../components";
import { UserForm } from "../components";
import { DownloadButton } from "../components/DownloadButton/DownloadButton";
import { Link, useParams } from "react-router-dom";
import { refreshUserAuth } from "../api";

export const PageBrand = () => {
  const userData = user();
  const params = useParams();

  const upperCaseId = params.id.toUpperCase();

  if (
    !["IBFT", "CITRG"].includes(upperCaseId) ||
    (upperCaseId === "CITRG" && !userData.apoloAccessEnabled)
  ) {
    window.location.href = "/";
  }

  // Defaults to CITRG
  let userHasPermissionToDownload =
    !!userData?.citrgBrandingUsageAcceptedTermsAt;

  let brand = {
    type: upperCaseId,
    termsUrl:
      "https://lp.terapeutaderesultados.com.br/contrato-marca-membro-citrg/",
    checkAcceptedTermsField: "citrg_branding_usage_accepted_terms_at",
    lastDownloadedField: "citrg_branding_usage_downloaded_at",
  };

  if (upperCaseId === "IBFT") {
    brand = {
      type: upperCaseId,
      termsUrl: "https://lp.terapeutaderesultados.com.br/contrato-marca-trg/",
      checkAcceptedTermsField: "branding_usage_accepted_terms_at",
      lastDownloadedField: "branding_usage_downloaded_at",
    };
    userHasPermissionToDownload = !!userData?.brandingUsageAcceptedTermsAt;
  }

  useEffect(() => {
    refreshUserAuth();
  }, []);

  return (
    <div>
      <TopBar content="IBFT — Uso da Marca" />

      <Container className="mt-5">
        <Row className="justify-content-center align-items-center">
          <Col md="5">
            <h2 className="w-100 text-center mb-4">Olá, {userData.name}.</h2>
            <Card>
              <Card.Body>
                <Card.Title as="h5">{brand.type} - Download</Card.Title>

                {userHasPermissionToDownload ? (
                  <>
                    <p>
                      Faça o download de todos os arquivos relacionados a marca.
                      Imagens, manual, fontes, etc.
                    </p>
                    <DownloadButton brand={brand} />
                  </>
                ) : (
                  <>
                    <p>
                      Para fazer o download e poder utilizar a marca, você deve
                      ser um terapeuta certificado e ter aceito os termos de uso
                      específicos de uso e divulgação da marca.
                    </p>

                    <UserForm brand={brand} />
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="text-center my-3">
        <Link to="/">Voltar para as marcas</Link>
      </div>
      <div className="text-center my-3">
        <Link to="/logout">Sair</Link>
      </div>
    </div>
  );
};

export default PageBrand;
