import { parseISO, format } from "date-fns";

export const nowWithTimeZoneInRailsFormat = () => {
  const d = new Date();
  return new Date(
    Date.UTC(
      d.getUTCFullYear(),
      d.getUTCMonth(),
      d.getUTCDate(),
      d.getUTCHours(),
      d.getUTCMinutes(),
      d.getUTCSeconds(),
      d.getUTCMilliseconds(),
    ),
  ).toISOString();
};

export const hasDate = value => {
  if (!value) {
    return "Não";
  }

  const parsedData = parseISO(value);

  return format(parsedData, "dd/MM/yy 'às' HH:mm");
};
