import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { logout } from "../components/utils/auth";

export const PageLogout = () => {
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    logout();
    setLoading(false);
  }, [setLoading]);

  if (loading) {
    return null;
  }

  return <Redirect to="/login" />;
};

export default PageLogout;
