import { withFormik } from "formik";
import { UserFormFields } from "./UserFormFields";
import { api, refreshUserAuth } from "../../../api";
import { nowWithTimeZoneInRailsFormat } from "../../utils/date_utils";

const defaultErrorMessage =
  "O request não pode ser feito, por favor verifique se todos os campos realmente estão preenchidos corretamente. Se o problema persistir, entre em contato conosco.";

export const UserForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const { brand } = props;

    return {
      acceptedTerms: brand.checkAcceptedTermsField,
      globalError: "",
      globalSuccess: "",
      lastDownloadedField: brand.lastDownloadedField,
      typeBrand: brand.type,
    };
  },

  // Custom sync validation
  validate: values => {
    const errors = {};

    if (!values.acceptedTerms) {
      errors.acceptedTerms =
        "Para conseguir baixar os arquivos você precisa aceitar os termos.";
    }

    return errors;
  },

  handleSubmit: async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);

    try {
      const request = await api.put("auth", {
        [values.acceptedTerms]: nowWithTimeZoneInRailsFormat(),
      });

      if (request.data?.data) {
        setSubmitting(true);

        global.Rollbar.info("Branding allowed terms Success", {
          rollbarData: request.data,
        });

        await refreshUserAuth();

        window.location.href = `/brand/${values.typeBrand}`;
      } else {
        setSubmitting(false);

        setFieldError(
          "global_error",
          "Não conseguimos salvar suas informações, tente novamente.",
        );
      }
    } catch (error) {
      setSubmitting(false);
      if (error.response) {
        setFieldError(
          "global_error",
          "Não foi possível atualizar suas informações.",
        );
        global.Rollbar.warning("Global Error", error.toJSON());
      } else {
        setFieldError("global_error", defaultErrorMessage);
        global.Rollbar.warning("Global Error", error.toJSON());
      }
    }
  },

  displayName: "UserForm",
})(UserFormFields);
