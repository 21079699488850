import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLogged, user } from "../utils/auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userData = user();

  let redirectedRoute = "/login";
  let renderComponent = isLogged();

  if (isLogged() && !userData?.isCertified) {
    redirectedRoute = "/terapeuta-nao-certificado";
    renderComponent = false;
  }

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={props =>
        renderComponent ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectedRoute} />
        )
      }
    />
  );
};

export default PrivateRoute;
